/**
 * For use as a tag template function. Takes a "templatized" SVG XML template
 * string and returns a function that constructs the final SVG string with the
 * provided values put in place of the right template expressions.
 *
 * For example, if you use it as:
 * const svgMaker = svgWithValues`<mask id="${'idPrefix'}mask1">`
 *
 * ...it will return a function that can be called with a values blob, like this:
 * const svgString = svgMaker({ idPrefix: 'myPrefix-' });
 *
 * ...and svgString will be '<mask id="myPrefix-mask1">'.
 *
 * Note that the expression is ${'idPrefix'} and not ${idPrefix}. This is used
 * in the code generated by Rubicon to make the individual icon components in
 * nicollet-icons-react/icons.
 */
export function svgWithValues(strings, ...expressions) {
  return values =>
    strings
      .map((string, index) => {
        const valueName = expressions[index];
        return string + (values[valueName] || '');
      })
      .join('');
}
