/**
 * Returns a spreadable object containing all the data attributes passed to the
 * component instance as data-* attributes and dataAttributes
 * @param {Object} props
 * @returns {Object}
 */
export function getDataAttributes(props) {
  const { dataAttributes = {} } = props;

  return {
    ...Object.entries(dataAttributes).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...{ [`data-${key}`]: value }
      }),
      {}
    ),
    ...Object.entries(props).reduce(
      (acc, [key, value]) =>
        /^data-/.test(key)
          ? {
              ...acc,
              ...{ [key]: value }
            }
          : acc,
      {}
    )
  };
}
