/* eslint complexity: ["error", {"max": 3}] */
import { isString } from '../functions/type';
import { cssLengthMatcher } from '../regex';

/**
 * Represents a CSS length value. Takes a string or number and splits it into
 * its numeric value and its unit so we can do math on it. If it's initialized
 * with a number, we assume that number to be a length in pixels.
 */
export class CSSLength {
  constructor(value) {
    if (typeof value === 'number') {
      this.initWithNumber(value);
    } else if (isString(value)) {
      this.initWithString(value);
    } else {
      this.throwCreationError(value);
    }
  }

  // Public methods

  toString() {
    return this.stringValue;
  }

  times(multiplier) {
    return `${this.number * multiplier}${this.unit}`;
  }

  dividedBy(divisor) {
    return `${this.number / divisor}${this.unit}`;
  }

  // Internal utilities

  initWithNumber(value) {
    if (value === 0) {
      this.setZero();
    } else {
      this.setParts(`${value}px`, value, 'px');
    }
  }

  initWithString(value) {
    const match = cssLengthMatcher.exec(value);

    // special cases first
    if (this.isZeroString(value)) {
      this.setZero();
    } else if (match) {
      const [, numberString, unit] = match;
      this.setParts(value, parseFloat(numberString), unit);
    } else {
      this.throwCreationError(value);
    }
  }

  setZero() {
    Object.assign(this, { stringValue: '0', number: 0, unit: null });
  }

  setParts(stringValue, number, unit) {
    Object.assign(this, { stringValue, number, unit });
  }

  throwCreationError(value) {
    throw new Error(`Couldn't create a CSS Length from the value: ${value}`);
  }

  isZeroString(value) {
    return value === '0' || value === '-0';
  }
}
