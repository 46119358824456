import ndsTokens from 'nicollet-tokens';

export const paramCase = str => str.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`);

const cssObjectToCssText = obj =>
  Object.entries(obj)
    .map(pair => `${paramCase(pair[0])}: ${pair[1]};`)
    .join('\n');

/*
 * The CSS font reset for Nicollet components. It emits all our defensive
 * defaults, but also accepts an optional jsStyle object of CSS properties to
 * fold into the defaults so you can get the specific styles you need.
 */
export const ndsFontReset = customCssObject => {
  const baselineCssObject = {
    fontFamily: ndsTokens.font.family.sansSerif,
    fontSize: ndsTokens.font.size.text.base,
    lineHeight: ndsTokens.font.lineHeight.text,
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0,
    textIndent: 0,
    textShadow: 'none',
    textTransform: 'none',
    verticalAlign: 'baseline',
    whiteSpace: 'normal'
  };
  const finalCssObject = Object.assign({}, baselineCssObject, customCssObject);

  return cssObjectToCssText(finalCssObject);
};
