import styled from 'styled-components';
import PropTypes from 'prop-types';
/*
 * Mixin function to use when you need something to appear only to a screenreader, and not render visually.
 * @param {boolean} focusable - set to true if the element is able to take keyboard focus
 */
export const ndsScreenReaderOnly = isFocusable => `
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  left: 0.1;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);

  /* the focusable option, if set emits these pseudoclasses */
  ${
    isFocusable
      ? `
    &:focus,
    &:active {
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      clip: auto;
    }`
      : ''
  }
`;

export const ScreenReaderOnly = styled.span`
  ${props => ndsScreenReaderOnly(props.isFocusable)}
`;

ScreenReaderOnly.propTypes = {
  focusable: PropTypes.bool,
  children: PropTypes.node
};
